import React, { useEffect, lazy } from 'react';
import { ThemeProvider } from 'styled-components';
import { GlobalStyle, theme as uiTheme } from '@teamfabric/copilot-ui';
import { GlobalStyle as Ds4GlobalStyle, CopilotUIProvider } from 'ds4-beta';
import { theme } from '../../lib/theme';
import NativeGlobalStyles from '../../lib/global-styles';
import { SnackbarProvider } from '../../hooks/snackbar';
import { Router, Redirect, navigate } from '@reach/router';
import LoadingState from '../../modules/onboarding-signup/LoadingState';

const Login = lazy(() => import('../../modules/login'));
const ForgotPassword = lazy(() => import('../../modules/forgot-password'));
const ResetPassword = lazy(() => import('../../modules/reset-password'));
const CreateAccountWrapper = lazy(() => import('../../modules/create-account'));
const MyAccount = lazy(() => import('../../modules/my-account'));
const NoAccount = lazy(() => import('../../modules/no-account'));
const OktaSignInWidget = lazy(() => import('../../modules/login/okta-widget'));
const TermsAndConditions = lazy(() =>
  import('../../modules/terms-and-conditions')
);
const OnboardingRenewal = lazy(() =>
  import('../../modules/onboarding-renewal')
);
const OnboardingSignup = lazy(() => import('../../modules/onboarding-signup'));

const App = () => {
  useEffect(() => {
    navigate(window.location.href);
  }, []);
  return (
    <SnackbarProvider>
      <CopilotUIProvider>
        <div data-testid='app'>
          <ThemeProvider theme={{ ...theme, uiTheme }}>
            <>
              <NativeGlobalStyles />
              <Ds4GlobalStyle />
              <GlobalStyle />
              <React.Suspense fallback={<LoadingState />}>
                <Router>
                  <ForgotPassword path='/auth/forgot-password' />
                  <Login path='/auth/login' />
                  {process.env.IDV2_LOGIN_PAGE_ENABLED && (
                    <OktaSignInWidget path='/auth/v2/login' />
                  )}
                  <ResetPassword path='/auth/reset-password' />
                  <CreateAccountWrapper path='/auth/create-account' />
                  <TermsAndConditions path='/auth/terms' />
                  <MyAccount path='/auth/my-account' />
                  <NoAccount path='/auth/no-account' />
                  <Redirect from='/' to='/auth/login' noThrow />
                  <OnboardingSignup path='/onboarding/signup' />
                  <OnboardingRenewal path='/onboarding/renewal' />
                </Router>
              </React.Suspense>
            </>
          </ThemeProvider>
        </div>
      </CopilotUIProvider>
    </SnackbarProvider>
  );
};

export default App;
