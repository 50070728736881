"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StyledSpinnerContainer = exports.StyledSpinner = void 0;
var styled_components_1 = __importStar(require("styled-components"));
var spinAnimation = (0, styled_components_1.keyframes)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  from {transform: rotate(0deg);}\n  to {transform: rotate(360deg);}\n"], ["\n  from {transform: rotate(0deg);}\n  to {transform: rotate(360deg);}\n"])));
var fillStyle = "\n  position: absolute;\n  left: 0;\n  top: 0;\n  width: 100%;\n  height: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n";
exports.StyledSpinner = styled_components_1.default.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  animation: 0.9s infinite linear;\n  animation-name: ", ";\n  border: 3px solid\n    ", ";\n  border-radius: 50%;\n  border-top-color: transparent;\n\n  display: block;\n  height: ", ";\n  transform: translate3d(0, 0, 0);\n  width: ", ";\n"], ["\n  animation: 0.9s infinite linear;\n  animation-name: ", ";\n  border: 3px solid\n    ", ";\n  border-radius: 50%;\n  border-top-color: transparent;\n\n  display: block;\n  height: ", ";\n  transform: translate3d(0, 0, 0);\n  width: ", ";\n"])), spinAnimation, function (props) {
    return props.theme.palette[props.color].main || props.theme.palette.grey.A600;
}, function (_a) {
    var small = _a.small;
    return (small ? '13px' : '36px');
}, function (_a) {
    var small = _a.small;
    return (small ? '13px' : '36px');
});
exports.StyledSpinnerContainer = styled_components_1.default.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: inline-block;\n  &[data-variant='fill'] {\n    ", ";\n  }\n  &[data-variant='fullScreen'] {\n    ", ";\n    background-color: rgba(0, 0, 0, 0.1);\n    //removed process.browser && validation\n    height: ", ";\n    z-index: ", ";\n    ", " {\n      height: ", ";\n      width: ", ";\n    }\n  }\n  &[data-variant='block'] {\n    align-items: center;\n    display: flex;\n    flex-shrink: 0;\n    height: 80px;\n    justify-content: center;\n    width: 100%;\n  }\n"], ["\n  display: inline-block;\n  &[data-variant='fill'] {\n    ", ";\n  }\n  &[data-variant='fullScreen'] {\n    ", ";\n    background-color: rgba(0, 0, 0, 0.1);\n    //removed process.browser && validation\n    height: ", ";\n    z-index: ", ";\n    ", " {\n      height: ", ";\n      width: ", ";\n    }\n  }\n  &[data-variant='block'] {\n    align-items: center;\n    display: flex;\n    flex-shrink: 0;\n    height: 80px;\n    justify-content: center;\n    width: 100%;\n  }\n"])), fillStyle, fillStyle, function (props) {
    return document.querySelector('.app_wrapper')
        ? "".concat(document.querySelector('.app_wrapper').clientHeight, "px")
        : '100%';
}, function (props) { return props.theme.zIndex.tooltip; }, exports.StyledSpinner, function (_a) {
    var small = _a.small;
    return (small ? '22px' : '44px');
}, function (_a) {
    var small = _a.small;
    return (small ? '22px' : '44px');
});
var templateObject_1, templateObject_2, templateObject_3;
