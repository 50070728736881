"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.KindsEnum = exports.ButtonSizes = exports.SpinnerSizes = void 0;
var SpinnerSizes;
(function (SpinnerSizes) {
    SpinnerSizes["normal"] = "normal";
    SpinnerSizes["fullScreen"] = "fullScreen";
    SpinnerSizes["block"] = "block";
    SpinnerSizes["fill"] = "fill";
})(SpinnerSizes = exports.SpinnerSizes || (exports.SpinnerSizes = {}));
var ButtonSizes;
(function (ButtonSizes) {
    ButtonSizes["large"] = "large";
})(ButtonSizes = exports.ButtonSizes || (exports.ButtonSizes = {}));
var KindsEnum;
(function (KindsEnum) {
    KindsEnum["Alert"] = "alert";
    KindsEnum["Success"] = "success";
    KindsEnum["Message"] = "message";
    KindsEnum["Notification"] = "notification";
    KindsEnum["Floating"] = "floating";
})(KindsEnum = exports.KindsEnum || (exports.KindsEnum = {}));
